import React, { useEffect } from 'react';
import PageHeader from '../../components/pageHeader/pageHeader';
import ThemeImage from '../../components/util/ThemeImage';
import About from './about';
import SlideAnimation from '../../components/util/Animations/SlideAnimation';

function AboutPage() {

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);


  return (<>

    <SlideAnimation in={true}>
      <PageHeader pageName="About Us" />

      {/* features-4 */}
      <section className="w3l-services-6-main home-services">
        <div className="services-6 py-5">
          <div className="container py-md-3">

            <About showBtn={false} />

         
          </div>
        </div>
      </section>
     
    
    </SlideAnimation>
  </>);
}

export default AboutPage;
